import React from "react"
import { useNavigate } from "react-router-dom"
import { SocialIcon } from "react-social-icons"
import { Stack, Typography } from "@mui/material"
import ButtonControl from "components/controls/ButtonControl"
import InfoCard from "../components/controls/InfoCard"

const secProps = { fontSize: "22px", fontWeight: "600" }

const Home = (): JSX.Element => {
  const navigate = useNavigate()
  const donate = () => {
    const targetRoute = "../options"
    navigate(targetRoute)
  }

  return (
    <Stack spacing={3}>
      <InfoCard themeColor="#FFFFFF">
        <Stack direction="column" spacing={1}>
          <Typography {...secProps}>About</Typography>
          <Typography>
            AHLA, a 501(c)(3) tax-exempt organization, is dedicated to loving,
            supporting and fundraising for all people with cancer related
            illnesses.
          </Typography>
        </Stack>
      </InfoCard>
      <InfoCard themeColor="#FFFFFF">
        <Stack direction="column" spacing={1}>
          <Typography {...secProps}>Our Mission</Typography>
          <Typography>
            To help and support the Lebanese people into getting back their
            dignity and their basic human needs.
          </Typography>
          <ButtonControl label="DONATE NOW" width="100px" onClick={donate} />
        </Stack>
      </InfoCard>
      <InfoCard themeColor="#FFFFFF">
        <Stack flexDirection="column" spacing={1}>
          <Typography {...secProps}>Partners</Typography>
          <Typography>
            <a href="https://www.barbara.im">Barbara Nassar Cancer Center</a>
          </Typography>
          <Typography>
            <a href="https://wedeservebetterfoundation.org">
              We Deserve Better Foundation
            </a>
          </Typography>
          <Typography>
            <a href="https://illumi-nationglobal.org/">
              Illumi-Nation Global, Inc.
            </a>
          </Typography>
          <Typography>
            <a href="https://redcross.org.lb/">Lebanese Red Cross</a>
          </Typography>
        </Stack>
      </InfoCard>
      <InfoCard themeColor="#FFFFFF">
        <Stack spacing={1}>
          <Stack spacing={1}>
            <Typography {...secProps}>Contact Us</Typography>
            <Stack sx={{ flexDirection: "row" }}>
              <Typography style={{ fontWeight: "bold" }}>Email: </Typography>
              <a href="mailto:ahlalebnen@gmail.com">ahlalebnen@gmail.com</a>
            </Stack>
          </Stack>
          <Stack>
            <Stack sx={{ flexDirection: "row" }}>
              <Typography style={{ fontWeight: "bold" }}>P.O.Box: </Typography>
              125
            </Stack>
            <Typography>Millbrae, CA 94030</Typography>
          </Stack>
          <Stack>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Tel: </span>(415) 948-3020
            </Typography>
          </Stack>
          <Stack
            sx={{
              position: "relative",
              bottom: "-15px",
            }}
          >
            {/* <img alt="facebook" height="32px" src={facebook} width="32px" /> */}
            <Stack flexDirection="row">
              <SocialIcon
                style={{ marginRight: "5px" }}
                url="https://www.facebook.com/profile.php?id=61560026070366&mibextid=LQQJ4d"
              />

              <SocialIcon url="https://x.com/ahlalebnen10452" />
            </Stack>
          </Stack>
        </Stack>
      </InfoCard>
    </Stack>
  )
}

export default Home
