import React from "react"
import { Stack, Typography } from "@mui/material"
import imgjune2_1 from "assets/images/events/June2_1.jpg"
import imgOct23 from "assets/images/events/Oct_23.jpeg"
import imgSep28 from "assets/images/events/Sep_28.jpg"
import InfoCard from "components/controls/InfoCard"
const Events = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: "center" }}>
      <InfoCard title="UPCOMING EVENTS">
        {/* <Stack spacing={1}></Stack> */}
      </InfoCard>
      <InfoCard title="PAST EVENTS">
        <Stack spacing={1}>
          <img
            alt="AHLA Event Sept 28"
            height="40%"
            src={imgSep28}
            width="100%"
          />
          <img alt="AHLA Event" height="40%" src={imgjune2_1} width="100%" />
          <img alt="AHLA Past Event" height="40%" src={imgOct23} width="100%" />
        </Stack>
      </InfoCard>
    </Stack>
  )
}

export default Events
